import { createSlice } from "@reduxjs/toolkit"

interface CartState {
  products: []
  quantity: {}
  total: number
}

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    products: [],
    quantity: {},
    total: 0,
  },
  reducers: {
    addProduct: (state, action) => {
      const productIndex = state.products.findIndex(
        item => item.id === action.payload.id
      )
      const productId = action.payload.id
      if (productIndex >= 0) {
        state.quantity[productId] = Number(state.quantity[productId]) + 1
        state.total += Number(action.payload.price)
      } else {
        state.products.push(action.payload)
        state.total += Number(action.payload.price)
        state.quantity[productId] = 1
      }
    },
    removeFromCart: (state, action) => {
      const productId = action.payload.id
      const nextCartProducts = state.products.filter(
        item => item.id !== productId
      )
      state.total -= Number(action.payload.price) * state.quantity[productId]
      delete state.quantity[productId]
      state.products = nextCartProducts
      state.quantity = state.quantity
    },
    clearCart: state => {
      state.products = []
      state.quantity = 0
      state.total = 0
    },
  },
})

export const { addProduct, removeFromCart, clearCart } = cartSlice.actions
export default cartSlice.reducer

import { configureStore, combineReducers } from "@reduxjs/toolkit"

import cartReducer from "./cart-slice"

export const store = configureStore({
  reducer: {
    cart: cartReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type Rootstate = ReturnType<typeof store.getState>
